import React from 'react';
import {CSSTransition, Transition, TransitionGroup} from "react-transition-group";
import ramenSpace from '../../images/space/ramenSpace.png'

import uuid from 'uuid'



function RamenSpace(props) {

    const renderSheep = () => {
        if (true) {
            return  (

                <div>
                    <img src={ramenSpace} style={sheep}/>
                </div>


            )
        }
    }

    return(
        <TransitionGroup>
            <CSSTransition
                key={uuid()}
                timeout={600}
                classNames='fade'>
                {renderSheep}
            </CSSTransition>
        </TransitionGroup>

    )

}


export default RamenSpace



const sheep = {
    position: 'fixed',
    zIndex: -1,
    maxHeight: '90%',
    maxWidth:'100%',
    left:'0',
    right:'0',
    top:'0',
    bottom:'0',
    margin:'auto'
}

