import React, {useEffect, useState} from 'react';


import btn_sheep from '../../images/btn_sheep.png'
import btn_sheep_active from '../../images/btn_sheep_active.png'
import btn_tree from '../../images/btn_tree.png'
import btn_tree_active from '../../images/btn_tree_active.png'
import btn_owl from '../../images/btn_owl.png'
import btn_owl_active from '../../images/btn_owl_active.png'
import btn_bird_active from '../../images/btn_bird_active.png'
import btn_bird from '../../images/btn_bird.png'
import btn_treeFace from '../../images/btn_treeFace.png'
import btn_treeFace_active from '../../images/btn_treeFace_active.png'
import back from '../../images/back.png'
import btn_shroom from '../../images/btn_shroom.png'
import btn_shroom_active from '../../images/btn_shroom_active.png'

import background from '../../images/alps/background.png'


import Tone from 'tone'


import Schaf from './Schaf'
import '../World.css'
import Tree from './Tree'
import Owl from "./Owl";
import Bird from './Bird'
import TreeFace from "./TreeFace";
import Shroom from './Shroom'
import RamenAlp from "./RamenAlp";



function Alps(props) {

    const [sheep, setSheep] = useState(false)
    const [tree, setTree] = useState(false)
    const [owl, setOwl] = useState(false)
    const [bird, setBird] = useState(false)
    const [treeFace, setTreeFace] = useState(false)
    const [shroom, setShroom] = useState(false)

    useEffect(() => {
        Tone.Transport.bpm.value = 120
        props.backgroundAlpsPlayer.mute = false

        props.sheepPlayer.mute = true
        props.treeFacePlayer.mute = true
        props.chordPlayer.mute = true
       /* props.birdPlayer.mute = true
        props.owlPlayer.mute = true*/
        props.treePlayer.mute = true

        props.backgroundAlpsPlayer.restart()
        props.chordPlayer.restart()
        props.sheepPlayer.restart()

        props.treePlayer.restart()

        props.treeFacePlayer.restart()


        Tone.Transport.start()

        Tone.Transport.scheduleRepeat(()=>{
            //Tone.Transport.position = 0;
            Tone.Transport.start()
        }, '16m')

    },[])


    const handleSheep = () => {
        if (!sheep) {

            props.sheepPlayer.mute = false
            setSheep(true)
        } else if (sheep) {

            props.sheepPlayer.mute = true
            setSheep(false)
        }
    }

    const handleShroom = () => {
        if (!shroom) {
            props.chordPlayer.mute = false
            setShroom(true)
        } else if (shroom) {
            props.chordPlayer.mute = true
            setShroom(false)
        }
    }

    const handleTreeFace = () => {
        if (!treeFace) {
            props.treeFacePlayer.mute = false
            setTreeFace(true)
        } else if (treeFace) {
            props.treeFacePlayer.mute = true
            //owlPlayer.mute = true
            setTreeFace(false)
        }
    }

    const handleTree = () => {
        if (!tree) {

            if (owl) {
                props.owlPlayer.start()
            }

            if (bird) {
                props.birdPlayer.start()

            }

            if (treeFace) {
                props.treeFacePlayer.mute = false
            }



            props.treePlayer.mute = false

            setTree(true)
        } else if (tree) {
            props.treePlayer.mute = true
            //Tone.Transport.clear(treeTone)
            //setTreeTone(null)
            //owlPlayer.mute = true
            setTree(false)

            props.owlPlayer.stop()


            props.treeFacePlayer.mute = true


            props.birdPlayer.stop()

        }
    }

    const handleOwl = () => {
        if (!owl) {

            props.owlPlayer.mute = false
            props.owlPlayer.restart()

            setOwl(true)
        } else if (owl) {
            props.owlPlayer.stop()

            //owlPlayer.mute = true
            setOwl(false)
        }
    }

    const handleBird = () => {
        if (!bird) {

            props.birdPlayer.mute = false
            props.birdPlayer.restart()

            setBird(true)
        } else if (bird) {
            props.birdPlayer.stop()

            //owlPlayer.mute = true
            setBird(false)
        }
    }

    const handleBack = () => {
        props.backgroundAlpsPlayer.mute = true
        props.sheepPlayer.mute = true
        props.treeFacePlayer.mute = true
        props.chordPlayer.mute = true
        props.birdPlayer.mute = true
        props.owlPlayer.mute = true
        props.treePlayer.mute = true

        props.backgroundAlpsPlayer.stop()
        props.sheepPlayer.stop()
        props.treePlayer.stop()
        props.treeFacePlayer.stop()
        props.chordPlayer.stop()
        props.birdPlayer.stop()
        props.owlPlayer.stop()


        Tone.Transport.stop();
        Tone.Transport.position = 0;
        Tone.Transport.cancel();
        //Tone.Transport.stop()
        props.choose(0)
    }





    return (

        <div>
            <img src={background} style={bgStyle}/>
            <Schaf show={sheep}/>
            <Shroom show={shroom}/>
            <Tree show={tree} />
            <Owl show={owl} show2={tree} />
            <Bird show={bird} show2={tree} />
            <TreeFace show={treeFace} show2={tree} />
            <RamenAlp/>
            <div style={buttons}>

               <img src={back}  style={backStyle} onClick = {handleBack} />


                <img src={shroom ? btn_shroom_active : btn_shroom}
                     onMouseOver={event => event.target.src = btn_shroom_active}
                     onMouseOut={event => (shroom ? event.target.src = btn_shroom_active : event.target.src = btn_shroom)}
                     style={crossStyle}
                     onClick = {handleShroom} />

                 <img src={sheep ? btn_sheep_active : btn_sheep}
                               onMouseOver={event => event.target.src = btn_sheep_active}
                               onMouseOut={event => (sheep ? event.target.src = btn_sheep_active : event.target.src = btn_sheep)}
                               style={crossStyle}
                               onClick = {handleSheep} />

                 <img src={tree ? btn_tree_active : btn_tree}
                               onMouseOver={event => event.target.src = btn_tree_active}
                               onMouseOut={event => (tree ? event.target.src = btn_tree_active : event.target.src = btn_tree)}
                               style={crossStyle}
                               onClick = {handleTree} />



                {tree ? <img src={owl ? btn_owl_active : btn_owl}
                             onMouseOver={event => event.target.src = btn_owl_active}
                             onMouseOut={event => (owl ? event.target.src = btn_owl_active : event.target.src = btn_owl)}
                             style={crossStyle}
                             onClick = {handleOwl} /> : null}

                {tree ? <img src={bird ? btn_bird_active : btn_bird}
                             onMouseOver={event => event.target.src = btn_bird_active}
                             onMouseOut={event => (bird ? event.target.src = btn_bird_active : event.target.src = btn_bird)}
                             style={crossStyle}
                             onClick = {handleBird} /> : null}

                {tree  ? <img src={treeFace ? btn_treeFace_active : btn_treeFace}
                             onMouseOver={event => event.target.src = btn_treeFace_active}
                             onMouseOut={event => (treeFace ? event.target.src = btn_treeFace_active : event.target.src = btn_treeFace)}
                             style={crossStyle}
                             onClick = {handleTreeFace} /> : null}


            </div>



        </div>


        /* <div style={style}>

         </div>*/
    )

}

export default Alps;

const buttons = {
    position:'fixed',
    textAlign: 'center',
    width: '100vw',
    bottom:'0',
}

const backStyle = {
    position: 'fixed',
    left: '1vw',
    bottom: '1vh',
    maxWidth: '10vw',
    maxHeight: '10vh',
}

const bgStyle = {
    position: 'fixed',
    zIndex: -1,
    maxHeight: '90%',
    maxWidth:'100%',
    left:'0',
    right:'0',
    top:'0',
    bottom:'0',
    margin:'auto'
}


const crossStyle = {
    maxWidth: '10vw',
    maxHeight: '10vh',
    margin: '1%',
}


