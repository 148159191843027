import React, {useEffect, useRef, useState} from 'react';

import loading from '../images/loading.png'





function Loading(props) {

const [show, setShow] = useState(false)

useEffect(()=>{
    const imgg = new Image()
    imgg.src = loading
    imgg.onload = ()=>setShow(true)
})



    return(
        <div>
            {show ? <img src={loading} style={sheep}/> : null}
        </div>

    )

}


export default Loading



const sheep = {
    position: 'fixed',
    zIndex: -1,
    maxHeight: '100%',
    maxWidth:'100%',
    left:'0',
    right:'0',
    top:'0',
    bottom:'0',
    margin:'auto'
}

