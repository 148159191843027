import React from 'react';
import {CSSTransition, Transition, TransitionGroup} from "react-transition-group";
import eli_off from '../../images/dschungel/eli_off.png'
import eli_on from '../../images/dschungel/eli_on.png'

import uuid from 'uuid'



function Elefant(props) {

    const renderSheep = () => {
        if (props.show && !props.show2) {
            return  (

                <div>
                    <img src={eli_off} style={sheep}/>
                </div>


            )
        } else if (props.show && props.show2) {
            return(
                <div>
                    <img src={eli_on} style={sheep}/>
                </div>
            )
        }
    }

    return(
        <TransitionGroup>
            <CSSTransition
                key={uuid()}
                timeout={600}
                classNames='fade'>
                {renderSheep}
            </CSSTransition>
        </TransitionGroup>

    )

}


export default Elefant



const sheep = {
    position: 'fixed',
    zIndex: -1,
    maxHeight: '90%',
    maxWidth:'100%',
    left:'0',
    right:'0',
    top:'0',
    bottom:'0',
    margin:'auto'
}

