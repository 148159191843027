import React, {useEffect, useRef, useState} from 'react';
import ok from '../images/ok.png'
import Tone from "tone";







function Ok(props) {







    return(
        <div>
            <img src={ok} style={sheep} onClick={()=>{
                Tone.context.resume()
                props.setLosjetz(true)}}/>
        </div>

    )

}


export default Ok



const sheep = {
    position: 'fixed',
    zIndex: -1,
    maxHeight: '100%',
    maxWidth:'100%',
    left:'0',
    right:'0',
    top:'0',
    bottom:'0',
    margin:'auto'
}

