import React, {useEffect, useState} from 'react';




import btn_eli from '../../images/btn_eli.png'
import btn_eli_active from '../../images/btn_eli_active.png'
import btn_nose from '../../images/btn_nose.png'
import btn_nose_active from '../../images/btn_nose_active.png'
import btn_parrot from '../../images/btn_parrot.png'
import btn_parrot_active from '../../images/btn_parrot_active.png'
import btn_snake from '../../images/btn_snake.png'
import btn_snake_active from '../../images/btn_snake_active.png'
import btn_tiger from '../../images/btn_tiger.png'
import btn_tiger_active from '../../images/btn_tiger_active.png'
import btn_treedj from '../../images/btn_treedj.png'
import btn_treedj_active from '../../images/btn_treedj_active.png'



import background from '../../images/dschungel/background.png'

import back from '../../images/back.png'

import Tone from 'tone'


import Flora from './Flora'
import Parrot from './Parrot'
import RamenDschungel from './RamenDschungel'
import Snake from './Snake'
import Tiger from './Tiger'
import Trees from './Trees'
import '../World.css'
import Elefant from "./Elefant";




function Dschungel(props) {

    const [eli_off, setEli_off] = useState(false)
    const [eli_on, setEli_on] = useState(false)
    const [flora, setFlora] = useState(false)
    const [parrot, setParrot] = useState(false)
    const [snake, setSnake] = useState(false)
    const [tiger, setTiger] = useState(false)
    const [trees, setTrees] = useState(false)

    useEffect(() => {
        Tone.Transport.bpm.value = 80

        props.backgroundDjPlayer.mute = false
        props.tigerPlayer.mute = true
        props.parrotPlayer.mute = true
        props.elephantPlayer.mute = true
        props.nosePlayer.mute = true
        props.snakePlayer.mute = true

        props.treesPlayer.mute = true

        props.backgroundDjPlayer.restart()
        props.snakePlayer.restart()
        props.treesPlayer.restart()

        props.tigerPlayer.restart()
        props.parrotPlayer.restart()
        props.elephantPlayer.restart()
        props.nosePlayer.restart()

        Tone.Transport.start()

        /*Tone.Transport.scheduleRepeat(()=>{
            //props.backgroundDjPlayer.start()
            props.treesPlayer.restart()
            props.tigerPlayer.restart()
            props.parrotPlayer.restart()
            props.elephantPlayer.restart()
            props.nosePlayer.restart()
            props.snakePlayer.restart()

        }, '16m')*/

        Tone.Transport.scheduleRepeat(()=>{
            //Tone.Transport.position = 0;
            Tone.Transport.start()
        }, '16m')

    },[])


    const handleTrees = () => {
        if (!trees) {

            if (parrot) {
                props.parrotPlayer.mute = false
            }

            props.treesPlayer.mute = false
            setTrees(true)
        } else if (trees) {

            props.parrotPlayer.mute = true
            props.treesPlayer.mute = true
            setTrees(false)
        }
    }

    const handleTiger = () => {
        if (!tiger) {
            props.tigerPlayer.mute = false
            setTiger(true)
        } else if (tiger) {

            props.tigerPlayer.mute = true
            setTiger(false)
        }
    }

    const handleSnake = () => {
        if (!snake) {
            props.snakePlayer.mute = false

            setSnake(true)
        } else if (snake) {

            props.snakePlayer.mute = true
            setSnake(false)
        }
    }

    const handleParrot = () => {
        if (!parrot) {


            props.parrotPlayer.mute = false

            setParrot(true)
        } else if (parrot) {
            props.parrotPlayer.mute = true
            setParrot(false)
        }
    }

    /*const handleFlora = () => {
        if (!flora) {


            setFlora(true)
        } else if (flora) {


            setFlora(false)
        }
    }*/

    const handleEli_off = () => {
        if (!eli_off) {

            if (eli_on) {
                props.nosePlayer.mute = false
            }

            props.elephantPlayer.mute = false
            setEli_off(true)
        } else if (eli_off) {
            props.nosePlayer.mute = true

            props.elephantPlayer.mute = true
            setEli_off(false)
        }
    }

    const handleEli_on = () => {
        if (!eli_on) {

            props.nosePlayer.mute = false
            setEli_on(true)
        } else if (eli_on) {

            props.nosePlayer.mute = true
            setEli_on(false)
        }
    }





    const handleBack = () => {

        props.backgroundDjPlayer.mute = true
        props.treesPlayer.mute = true
        props.tigerPlayer.mute = true
        props.parrotPlayer.mute = true
        props.elephantPlayer.mute = true
        props.nosePlayer.mute = true
        props.snakePlayer.mute = true

        props.backgroundDjPlayer.stop()
        props.treesPlayer.stop()
        props.tigerPlayer.stop()
        props.parrotPlayer.stop()
        props.elephantPlayer.stop()
        props.nosePlayer.stop()
        props.snakePlayer.stop()
        Tone.Transport.stop();
        Tone.Transport.position = 0;
        Tone.Transport.cancel();
        //Tone.Transport.stop()
        props.choose(0)
    }





    return (

        <div>
            <img src={background} style={bgStyle}/>

            <Flora show={flora}/>

            <Trees show={trees}/>
            <Elefant show={eli_off} show2={eli_on}/>
            {/*<Eli_off show={eli_off}/>
            <Eli_on show={eli_on} show2={eli_off}/>*/}

            <Parrot show={parrot} show2={trees}/>
            <Snake show={snake}/>
            <Tiger show={tiger}/>
            <RamenDschungel/>
            <div style={buttons}>

                <img src={back}  style={backStyle} onClick = {handleBack} />


                {/*<img src={flora ? btn_flora_active : btn_flora}
                     onMouseOver={event => event.target.src = btn_flora_active}
                     onMouseOut={event => (flora ? event.target.src = btn_flora_active : event.target.src = btn_flora)}
                     style={crossStyle}
                     onClick = {handleFlora} />*/}

                <img src={trees ? btn_treedj_active : btn_treedj}
                     onMouseOver={event => event.target.src = btn_treedj_active}
                     onMouseOut={event => (trees ? event.target.src = btn_treedj_active : event.target.src = btn_treedj)}
                     style={crossStyle}
                     onClick = {handleTrees} />

                {trees ? <img src={parrot ? btn_parrot_active : btn_parrot}
                              onMouseOver={event => event.target.src = btn_parrot_active}
                              onMouseOut={event => (parrot ? event.target.src = btn_parrot_active : event.target.src = btn_parrot)}
                              style={crossStyle}
                              onClick = {handleParrot} /> : null}

                <img src={eli_off ? btn_eli_active : btn_eli}
                     onMouseOver={event => event.target.src = btn_eli_active}
                     onMouseOut={event => (eli_off ? event.target.src = btn_eli_active : event.target.src = btn_eli)}
                     style={crossStyle}
                     onClick = {handleEli_off} />

                {eli_off ? <img src={eli_on ? btn_nose_active : btn_nose}
                                onMouseOver={event => event.target.src = btn_nose_active}
                                onMouseOut={event => (eli_on ? event.target.src = btn_nose_active : event.target.src = btn_nose)}
                                style={crossStyle}
                                onClick = {handleEli_on} /> : null}





                <img src={snake ? btn_snake_active : btn_snake}
                     onMouseOver={event => event.target.src = btn_snake_active}
                     onMouseOut={event => (snake ? event.target.src = btn_snake_active : event.target.src = btn_snake)}
                     style={crossStyle}
                     onClick = {handleSnake} />

                <img src={tiger ? btn_tiger_active : btn_tiger}
                     onMouseOver={event => event.target.src = btn_tiger_active}
                     onMouseOut={event => (tiger ? event.target.src = btn_tiger_active : event.target.src = btn_tiger)}
                     style={crossStyle}
                     onClick = {handleTiger} />






            </div>



        </div>


        /* <div style={style}>

         </div>*/
    )

}

export default Dschungel;

const buttons = {
    position:'fixed',
    textAlign: 'center',
    width: '100vw',
    bottom:'0',
}

const backStyle = {
    position: 'fixed',
    left: '1vw',
    bottom: '1vh',
    maxWidth: '10vw',
    maxHeight: '10vh',
}

const bgStyle = {
    position: 'fixed',
    zIndex: -1,
    maxHeight: '90%',
    maxWidth:'100%',
    left:'0',
    right:'0',
    top:'0',
    bottom:'0',
    margin:'auto'
}


const crossStyle = {
    maxWidth: '10vw',
    maxHeight: '10vh',
    margin: '1%',
}


