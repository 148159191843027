import React, {useEffect, useState} from 'react';

import alps from '../images/alps.png'
import alps_active from '../images/alps_active.png'
import space from '../images/space.png'
import space_active from '../images/space_active.png'
import dschungel from '../images/dschungel.png'
import dschungel_active from '../images/dschungel_active.png'
import Tone from 'tone'


function Menu(props) {

    useEffect(()=> {
        Tone.context.resume()
        Tone.Transport.bpm.value = 108

        props.menuMusicPlayer.mute = false

        Tone.Transport.start()
        console.log(Tone.Transport.state)
        props.menuMusicPlayer.restart()
        Tone.Transport.scheduleRepeat(()=>{
            //Tone.Transport.position = 0;
            Tone.Transport.start()
        }, '8m')
    },[] )

    const handleHover = ()=> {
        const rand = Math.floor(Math.random() * Math.floor(14));
        if ( rand === 0 ) {props.menuPlayers.get('a').restart()}
        if ( rand === 1 ) {props.menuPlayers.get('b').restart()}
        if ( rand === 2 ) {props.menuPlayers.get('c').restart()}
        if ( rand === 3 ) {props.menuPlayers.get('d').restart()}
        if ( rand === 4 ) {props.menuPlayers.get('e').restart()}
        if ( rand === 5 ) {props.menuPlayers.get('f').restart()}
        if ( rand === 6 ) {props.menuPlayers.get('g').restart()}
        if ( rand === 7 ) {props.menuPlayers.get('h').restart()}
        if ( rand === 8 ) {props.menuPlayers.get('i').restart()}
        if ( rand === 9 ) {props.menuPlayers.get('j').restart()}
        if ( rand === 10 ){props.menuPlayers.get('k').restart()}
        if ( rand === 11) {props.menuPlayers.get('l').restart()}
        if ( rand === 12) {props.menuPlayers.get('m').restart()}
        if ( rand === 13) {props.menuPlayers.get('n').restart()}

    }

    const handleAlps = () => {



        //Tone.Transport.start()
        /*props.chordPlayer.start()
        props.chordPlayer.mute = true
        props.sheepPlayer.start()
        props.sheepPlayer.mute = true
        props.treePlayer.start()
        props.treePlayer.mute = true
        props.treeFacePlayer.start()
        props.treeFacePlayer.mute = true*/

        props.menuMusicPlayer.mute = true
        props.menuMusicPlayer.stop()
        Tone.Transport.stop();
        Tone.Transport.position = 0;
        Tone.Transport.cancel();
        Tone.context.resume()
        props.choose(1)

    }

    const handleSpace = () => {


        //Tone.Transport.start()
        /*props.alienPlayer.start()
        props.alienPlayer.mute = true
        props.backgroundPlayer.start()
        props.moonPlayer.start()
        props.moonPlayer.mute = true
        props.spherePlayer.start()
        props.spherePlayer.mute = true
        props.sputnikPlayer.start()
        props.sputnikPlayer.mute = true
        props.nebelPlayer.start()
        props.nebelPlayer.mute = true*/
        props.menuMusicPlayer.mute = true
        props.menuMusicPlayer.stop()
        Tone.Transport.stop();
        Tone.Transport.position = 0;
        Tone.Transport.cancel();
        Tone.context.resume()
        props.choose(2)

    }

    const handleDschungel = () => {


        //Tone.Transport.start()
        /*props.backgroundDjPlayer.start()
        props.treesPlayer.start()
        props.treesPlayer.mute = true
        props.tigerPlayer.start()
        props.tigerPlayer.mute = true
        props.parrotPlayer.start()
        props.parrotPlayer.mute = true
        props.elephantPlayer.start()
        props.elephantPlayer.mute = true
        props.nosePlayer.start()
        props.nosePlayer.mute = true
        props.snakePlayer.start()
        props.snakePlayer.mute = true*/

        /*Tone.Transport.stop();
        Tone.Transport.position = 0;
        Tone.Transport.cancel();*/
        props.menuMusicPlayer.mute = true
        props.menuMusicPlayer.stop()
        Tone.Transport.stop();
        Tone.Transport.position = 0;
        Tone.Transport.cancel();
        Tone.context.resume()
        props.choose(3)

    }

    return (

        <div>
            <img src={props.bg} style={bgStyle}/>
            <div style={innen}>

                <img src={alps} onMouseOver={(event) => {
                    event.target.src = alps_active
                    handleHover()
                }}
                     onMouseOut={(event) => {
                         event.target.src = alps
                         handleHover()
                     }}
                     style={crossStyle}
                     onClick={handleAlps}/>
                <img src={space} onMouseOver={(event) => {
                    event.target.src = space_active
                    handleHover()
                }}
                     onMouseOut={(event) => {
                         event.target.src = space
                        handleHover()
                     }}
                     style={crossStyle}
                     onClick={handleSpace}/>
                <img src={dschungel} onMouseOver={(event) => {
                    event.target.src = dschungel_active
                    handleHover()
                }}
                     onMouseOut={(event) =>{
                         event.target.src = dschungel
                        handleHover()
                     }}
                     style={crossStyle}
                     onClick={handleDschungel}/>
            </div>

        </div>

    )
}

export default Menu

const ausen = {
    position: 'relative',


}

const innen = {
    position:'fixed',
    textAlign: 'center',
    width: '100vw',
    height:'100vh',
    top:'40%',

}

const crossStyle = {
    maxWidth: '20%',
    maxHeight: '20%',

    margin: '1%',
}

const bgStyle = {
    position: 'fixed',
    zIndex: -1,
    maxHeight: '90%',
    maxWidth:'100%',
    left:'0',
    right:'0',
    top:'0',
    bottom:'0',
    margin:'auto'
}