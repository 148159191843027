import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import Menu from './components/Menu'
import Alps from './components/Alps/Alps'
import Space from './components/Space/Space'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Tone from "tone";

import loading from './images/loading.png'
import loading1 from './images/loading1.png'
import loading2 from './images/loading2.png'
import loading3 from './images/loading3.png'
import ok from './images/ok.png'
import Loading from "./components/Loading";

import bg from './images/bg.png'
import alps from './images/alps.png'
import alps_active from './images/alps_active.png'
import space from './images/space.png'
import space_active from './images/space_active.png'
import dschungel from './images/dschungel.png'
import dschungel_active from './images/dschungel_active.png'

import sphereImg from './images/space/sphere.png'
import starsImg from './images/space/stars.png'
import alienImg from './images/space/alien.png'
import moonImg from './images/space/moon.png'
import nebelImg from './images/space/nebel.png'
import ramenSpaceImg from './images/space/ramenSpace.png'
import sputnikImg from './images/space/sputnik.png'

import btn_alien from './images/btn_alien.png'
import btn_alien_active from './images/btn_alien_active.png'
import btn_moon from './images/btn_moon.png'
import btn_moon_active from './images/btn_moon_active.png'
import btn_nebel from './images/btn_nebel.png'
import btn_nebel_active from './images/btn_nebel_active.png'
import btn_sputnik from './images/btn_sputnik.png'
import btn_sputnik_active from './images/btn_sputnik_active.png'
import btn_sphere from './images/btn_sphere.png'
import btn_sphere_active from './images/btn_sphere_active.png'
import backgroundImgSpace from './images/space/background.png'
import back from './images/back.png'



import btn_eli from './images/btn_eli.png'
import btn_eli_active from './images/btn_eli_active.png'
import btn_nose from './images/btn_nose.png'
import btn_nose_active from './images/btn_nose_active.png'
import btn_parrot from './images/btn_parrot.png'
import btn_parrot_active from './images/btn_parrot_active.png'
import btn_snake from './images/btn_snake.png'
import btn_snake_active from './images/btn_snake_active.png'
import btn_tiger from './images/btn_tiger.png'
import btn_tiger_active from './images/btn_tiger_active.png'
import btn_treedj from './images/btn_treedj.png'
import btn_treedj_active from './images/btn_treedj_active.png'
import backgroundDjImg from './images/dschungel/background.png'
import treesImg from './images/dschungel/trees.png'
import tigerImg from './images/dschungel/tiger.png'
import snakeImg from './images/dschungel/snake.png'
import ramenDschungel from './images/dschungel/ramen_dschungel.png'
import parrotImg from './images/dschungel/parrot.png'
import flora from './images/dschungel/flora.png'
import eli_on from './images/dschungel/eli_on.png'
import eli_off from './images/dschungel/eli_off.png'

import treeFace from './images/alps/treeFace.png'
import tree from './images/alps/tree.png'
import pilz from './images/alps/pilz.png'
import sheep2 from './images/alps/sheep.png'
import ramen from './images/alps/ramen.png'
import owl from './images/alps/owl.png'
import bird from './images/alps/bird.png'
import btn_sheep from './images/btn_sheep.png'
import btn_sheep_active from './images/btn_sheep_active.png'
import btn_tree from './images/btn_tree.png'
import btn_tree_active from './images/btn_tree_active.png'
import btn_owl from './images/btn_owl.png'
import btn_owl_active from './images/btn_owl_active.png'
import btn_bird_active from './images/btn_bird_active.png'
import btn_bird from './images/btn_bird.png'
import btn_treeFace from './images/btn_treeFace.png'
import btn_treeFace_active from './images/btn_treeFace_active.png'
import btn_shroom from './images/btn_shroom.png'
import btn_shroom_active from './images/btn_shroom_active.png'
import backgroundAlpsImg from './images/alps/background.png'


import menuMusic from './tone/menuMusic.m4a'
import a from './tone/a.m4a'
import b from './tone/b.m4a'
import c from './tone/c.m4a'
import d from './tone/d.m4a'
import e from './tone/e.m4a'
import f from './tone/f.m4a'
import g from './tone/g.m4a'
import h from './tone/h.m4a'
import i from './tone/i.m4a'
import j from './tone/j.m4a'
import k from './tone/k.m4a'
import l from './tone/l.m4a'
import m from './tone/m.m4a'
import n from './tone/n.m4a'




import backgroundAlps from './tone/backgroundAlps.m4a'
import chords from "./tone/chords.m4a";
import sax from "./tone/sax.m4a";
import drums from "./tone/drums.m4a";
import flute from "./tone/flute.m4a";
import maeh from "./tone/maeh.m4a";
import bass from "./tone/bass.m4a";

import alien from './tone/alien.m4a'
import background from './tone/background.m4a'
import moon from './tone/moon.m4a'
import sphere from './tone/sphere.m4a'
import sputnik from './tone/sputnik.m4a'
import nebel from './tone/nebel.m4a'

import backgroundDj from './tone/backgroundDj.m4a'
import trees from './tone/trees.m4a'
import tiger from './tone/tiger.m4a'
import parrot from './tone/parrot.m4a'
import elephant from './tone/elephant.m4a'
import nose from './tone/nose.m4a'
import snake from './tone/snake.m4a'

import Dschungel from "./components/Dschungel/Dschungel";
import Ok from "./components/Ok";


function App() {

    const [losjetz, setLosjetz] = useState(false)

    const [choice, setChoice] = useState(0);
    const [menuMusicPlayer, setMenuMusicPlayer] = useState()
    const [menuPlayers, setMenuPlayers] = useState()

    const [backgroundAlpsPlyer, setBackgroundAlpsPlayer] = useState()
    const [sheepPlayer, setSheepPlayer] = useState()
    const [treePlayer, setTreePlayer] = useState()
    const [owlPlayer, setOwlPlayer] = useState()
    const [birdPlayer, setBirdPlayer] = useState()
    const [treeFacePlayer, setTreeFacePlayer] = useState()
    const [chordPlayer, setChordPlayer] = useState()

    const [alienPlayer, setAlienplayer] = useState()
    const [backgroundPlayer, setBackgroundPlayer] = useState()
    const [moonPlayer, setMoonPlayer] = useState()
    const [spherePlayer, setSpherePlayer] = useState()
    const [sputnikPlayer, setSputnikPlayer] = useState()
    const [nebelPlayer, setNebelPlayer] = useState()

    const [backgroundDjPlayer, setBackgroundDjPlayer] = useState()
    const [treesPlayer, setTreesPlayer] = useState()
    const [tigerPlayer, setTigerPlayer] = useState()
    const [parrotPlayer, setParrotPlayer] = useState()
    const [elephantPlayer, setElephantPlayer] = useState()
    const [nosePlayer, setNosePlayer] = useState()
    const [snakePlayer, setSnakePlayer] = useState()





    const [loaded, setLoaded] = useState(false)
    const [imgLoad, setImageLoad] = useState(false)



    const count = useRef(0)
    const handleCount = () => {
        count.current += 1
        console.log('?')
        if (count.current >= 67) {
            setImageLoad(true)

        }
    }


    const loadPics =   () => {
        const pics = [ bg, alps,
            alps_active, space, space_active, dschungel, dschungel_active,sphereImg, starsImg, alienImg,moonImg, nebelImg, ramenSpaceImg, sputnikImg, btn_alien,
            btn_alien_active, btn_moon, btn_moon_active,btn_nebel, btn_nebel_active, btn_sputnik, btn_sputnik_active,
            btn_sphere,btn_sphere_active, backgroundImgSpace, back,btn_eli, btn_eli_active,btn_nose,btn_nose_active,
            btn_parrot,btn_parrot_active, btn_snake,btn_snake_active,btn_tiger,btn_tiger_active, btn_treedj,btn_treedj_active,backgroundDjImg
            ,treesImg,tigerImg,snakeImg,ramenDschungel,parrotImg,flora,eli_on,eli_off, treeFace,tree,pilz,sheep2,ramen,owl,bird,
            btn_sheep,btn_sheep_active,btn_tree,btn_tree_active,btn_owl,btn_owl_active,btn_bird, btn_bird_active,btn_treeFace,
            btn_treeFace_active,btn_shroom,btn_shroom_active,backgroundAlpsImg]

        const lol =    pics.forEach( (img) => {

            const pic = new Image()
            pic.src = img
            pic.onload =   handleCount


        })
    }

    useEffect( () => {

        /*const sphereImgage = new Image()
        sphereImgage.onload = () => {
            handleCount()
        }
        sphereImgage.src = sphereImg

        const starsImgage = new Image()
        starsImgage.onload = () => {
            handleCount()
        }
        starsImgage.src = starsImg*/



        loadPics()




        const menuMusicPlayer = new Tone.Player(menuMusic)

        const menuPlayers = new Tone.Players({
            'a' : a,
            'b' : b,
            'c':c,
            'd':d,
            'e':e,
            'f':f,
            'g':g,
            'h':h,
            'i':i,
            'j':j,
            'k':k,
            'l':l,
            'm':m,
            'n':n,
        })




        const backgroundAlpsPlayer = new Tone.Player(backgroundAlps)
        const chordPlayer = new Tone.Player(chords)
        const saxPlayer = new Tone.Player(sax)
        const drumPlayer = new Tone.Player(drums)
        const birdPlayer = new Tone.Player(flute)
        const maehPlayer = new Tone.Player(maeh)
        const facePlayer = new Tone.Player(bass)


        const alienPlayer = new Tone.Player(alien)
        const backgroundPlayer = new Tone.Player(background)
        const moonPlayer = new Tone.Player(moon)
        const spherePlayer = new Tone.Player(sphere)
        const sputnikPlayer = new Tone.Player(sputnik)
        const nebelPlayer = new Tone.Player(nebel)

        const backgroundDjPlayer = new Tone.Player(backgroundDj)
        const treesPlayer = new Tone.Player(trees)
        const tigerPlayer = new Tone.Player(tiger)
        const parrotPlayer = new Tone.Player(parrot)
        const elephantPlayer = new Tone.Player(elephant)
        const nosePlayer = new Tone.Player(nose)
        const snakePlayer = new Tone.Player(snake)



        Tone.Buffer.on('load', () => {
            //Tone.Transport.bpm.value = 108
            menuPlayers.get('a').toMaster().volume.value = -15
            menuPlayers.get('b').toMaster().volume.value = -15
            menuPlayers.get('c').toMaster().volume.value = -15
            menuPlayers.get('d').toMaster().volume.value = -15
            menuPlayers.get('e').toMaster().volume.value = -15
            menuPlayers.get('f').toMaster().volume.value = -15
            menuPlayers.get('g').toMaster().volume.value = -15
            menuPlayers.get('h').toMaster().volume.value = -15
            menuPlayers.get('i').toMaster().volume.value = -15
            menuPlayers.get('j').toMaster().volume.value = -15
            menuPlayers.get('k').toMaster().volume.value = -15
            menuPlayers.get('l').toMaster().volume.value = -15
            menuPlayers.get('m').toMaster().volume.value = -15
            menuPlayers.get('n').toMaster().volume.value = -15

            setMenuPlayers(menuPlayers)

            menuMusicPlayer.loop = true
            menuMusicPlayer.loopEnd = '8m'
            menuMusicPlayer.sync().toMaster()
            setMenuMusicPlayer(menuMusicPlayer)

            backgroundAlpsPlayer.loop = true
            backgroundAlpsPlayer.loopEnd = '8m'
            backgroundAlpsPlayer.sync().toMaster()
            setBackgroundAlpsPlayer(backgroundAlpsPlayer)

            chordPlayer.loop = true
            chordPlayer.loopEnd = '8m'
            chordPlayer.sync().toMaster()
            setChordPlayer(chordPlayer)

            saxPlayer.loop = true
            saxPlayer.loopEnd = '16m'
            saxPlayer.sync().toMaster()
            setOwlPlayer(saxPlayer)

            drumPlayer.loop = true
            drumPlayer.loopEnd = '16m'
            drumPlayer.sync().toMaster()
            setTreePlayer(drumPlayer)

            birdPlayer.loop = true
            birdPlayer.loopEnd = '16m'
            birdPlayer.sync().toMaster()
            setBirdPlayer(birdPlayer)

            maehPlayer.loop = true
            maehPlayer.loopEnd = '16m'
            maehPlayer.sync().toMaster()
            setSheepPlayer(maehPlayer)

            facePlayer.loop = true
            facePlayer.loopEnd = '16m'
            facePlayer.sync().toMaster()
            setTreeFacePlayer(facePlayer)


            alienPlayer.loop = true
            alienPlayer.loopEnd = '8m'
            alienPlayer.sync().toMaster()
            setAlienplayer(alienPlayer)

            backgroundPlayer.loop = true
            backgroundPlayer.loopEnd = '8m'
            backgroundPlayer.sync().toMaster()
            setBackgroundPlayer(backgroundPlayer)

            moonPlayer.loop = true
            moonPlayer.loopEnd = '8m'
            moonPlayer.sync().toMaster()
            setMoonPlayer(moonPlayer)


            spherePlayer.loop = true
            spherePlayer.loopEnd = '4m'
            spherePlayer.sync().toMaster()
            setSpherePlayer(spherePlayer)

            sputnikPlayer.loop = true
            sputnikPlayer.loopEnd = '8m'
            sputnikPlayer.sync().toMaster()
            setSputnikPlayer(sputnikPlayer)


            nebelPlayer.loop = true
            nebelPlayer.loopEnd = '8m'
            nebelPlayer.sync().toMaster()
            setNebelPlayer(nebelPlayer)


            backgroundDjPlayer.loop = true
            backgroundDjPlayer.loopEnd = '6m'
            backgroundDjPlayer.sync().toMaster()
            setBackgroundDjPlayer(backgroundDjPlayer)

            snakePlayer.loop = true
            snakePlayer.loopEnd = '4m'
            snakePlayer.sync().toMaster()
            setSnakePlayer(snakePlayer)

            treesPlayer.loop = true
            treesPlayer.loopEnd = '8m'
            treesPlayer.sync().toMaster()
            setTreesPlayer(treesPlayer)

            tigerPlayer.loop = true
            tigerPlayer.loopEnd = '8m'
            tigerPlayer.sync().toMaster()
            setTigerPlayer(tigerPlayer)

            parrotPlayer.loop = true
            parrotPlayer.loopEnd = '8m'
            parrotPlayer.sync().toMaster()
            setParrotPlayer(parrotPlayer)

            elephantPlayer.loop = true
            elephantPlayer.loopEnd = '8m'
            elephantPlayer.sync().toMaster()
            setElephantPlayer(elephantPlayer)

            nosePlayer.loop = true
            nosePlayer.loopEnd = '8m'
            nosePlayer.sync().toMaster()
            setNosePlayer(nosePlayer)


            Tone.Transport.start()

            setLoaded(true)

            //Tone.Transport.start()

        })


    },[])




    const test = () => {
        if (loaded && imgLoad) {
            if (choice === 0) {

                Tone.Transport.bpm.value = 108


                return (
                    <div>
                        <div style={{position:'absolute'}}>
                            <Menu choose={setChoice}

                                  menuPlayers={menuPlayers}
                                  menuMusicPlayer={menuMusicPlayer}

                                  bg={bg}
                                  backgroundAlpsPlayer={backgroundAlpsPlyer}
                                  setBackgroundAlpsPlayer={setBackgroundAlpsPlayer}
                                  sheepPlayer={sheepPlayer}
                                  setSheepPlayer={setSheepPlayer}
                                  treePlayer={treePlayer}
                                  setTreePlayer={setTreePlayer}
                                  owlPlayer={owlPlayer}
                                  setOwlPlayer={setOwlPlayer}
                                  birdPlayer={birdPlayer}
                                  setBirdPlayer={setBirdPlayer}
                                  treeFacePlayer={treeFacePlayer}
                                  setTreeFacePlayer={setTreeFacePlayer}
                                  chordPlayer={chordPlayer}
                                  setChordPlayer={setChordPlayer}

                                  alienPlayer={alienPlayer}
                                  backgroundPlayer={backgroundPlayer}
                                  moonPlayer={moonPlayer}
                                  spherePlayer={spherePlayer}
                                  sputnikPlayer={sputnikPlayer}
                                  nebelPlayer={nebelPlayer}
                                  setAlienplayer={setAlienplayer}
                                  setBackgroundPlayer={setBackgroundPlayer}
                                  setMoonPlayer={setMoonPlayer}
                                  setSpherePlayer={setSpherePlayer}
                                  setSputnikPlayer={setSputnikPlayer}
                                  setNebelPlayer={setNebelPlayer}

                                  backgroundDjPlayer={backgroundDjPlayer}
                                  treesPlayer={treesPlayer}
                                  tigerPlayer={tigerPlayer}
                                  parrotPlayer={parrotPlayer}
                                  elephantPlayer={elephantPlayer}
                                  nosePlayer={nosePlayer}
                                  snakePlayer={snakePlayer}
                                  setBackgroundDjPlayer={setBackgroundDjPlayer}
                                  setTreesPlayer={setTreesPlayer}
                                  setTigerPlayer={setTigerPlayer}
                                  setParrotPlayer={setParrotPlayer}
                                  setElephantPlayer={setElephantPlayer}
                                  setNosePlayer={setNosePlayer}
                                  setSnakePlayer={setSnakePlayer}

                            />
                        </div>

                    </div>

                )
            } else if (choice === 1) {

                Tone.Transport.bpm.value = 120
                return (
                    <div className={'App'}>
                        <div style={{position:'absolute'}}>
                            <Alps choose={setChoice}

                                  menuMusicPlayer={menuMusicPlayer}

                                  backgroundAlpsPlayer={backgroundAlpsPlyer}
                                  setBackgroundAlpsPlayer={setBackgroundAlpsPlayer}
                                  sheepPlayer={sheepPlayer}
                                  setSheepPlayer={setSheepPlayer}
                                  treePlayer={treePlayer}
                                  setTreePlayer={setTreePlayer}
                                  owlPlayer={owlPlayer}
                                  setOwlPlayer={setOwlPlayer}
                                  birdPlayer={birdPlayer}
                                  setBirdPlayer={setBirdPlayer}
                                  treeFacePlayer={treeFacePlayer}
                                  setTreeFacePlayer={setTreeFacePlayer}
                                  chordPlayer={chordPlayer}
                                  setChordPlayer={setChordPlayer}
                            />
                        </div>
                    </div>
                )
            } else if (choice === 2) {

                Tone.Transport.bpm.value = 120
                return (
                    <div className={'App'}>
                        <div style={{position:'absolute'}}>
                            <Space choose={setChoice}

                                   sphereImg={sphereImg}

                                   alienPlayer={alienPlayer}
                                   backgroundPlayer={backgroundPlayer}
                                   moonPlayer={moonPlayer}
                                   spherePlayer={spherePlayer}
                                   sputnikPlayer={sputnikPlayer}
                                   nebelPlayer={nebelPlayer}
                                   setAlienplayer={setAlienplayer}
                                   setBackgroundPlayer={setBackgroundPlayer}
                                   setMoonPlayer={setMoonPlayer}
                                   setSpherePlayer={setSpherePlayer}
                                   setSputnikPlayer={setSputnikPlayer}
                                   setNebelPlayer={setNebelPlayer}
                            />
                        </div>
                    </div>
                )
            } else if (choice === 3) {

                Tone.Transport.bpm.value = 80
                return (
                    <div className={'App'}>
                        <div style={{position:'absolute'}}>
                            <Dschungel choose={setChoice}
                                       backgroundDjPlayer={backgroundDjPlayer}
                                       treesPlayer={treesPlayer}
                                       tigerPlayer={tigerPlayer}
                                       parrotPlayer={parrotPlayer}
                                       elephantPlayer={elephantPlayer}
                                       nosePlayer={nosePlayer}
                                       snakePlayer={snakePlayer}
                                       setBackgroundDjPlayer={setBackgroundDjPlayer}
                                       setTreesPlayer={setTreesPlayer}
                                       setTigerPlayer={setTigerPlayer}
                                       setParrotPlayer={setParrotPlayer}
                                       setElephantPlayer={setElephantPlayer}
                                       setNosePlayer={setNosePlayer}
                                       setSnakePlayer={setSnakePlayer}
                            />
                        </div>
                    </div>
                )
        }

    }
    }



    return (

        <div>
            <TransitionGroup style={{position:'relative'}}>
                <CSSTransition
                    key={choice}
                    timeout={600}
                    classNames={'fade'}>

                    {losjetz ? test : (loaded && imgLoad ? <Ok setLosjetz={setLosjetz}/> : <Loading/>)}
                </CSSTransition>
            </TransitionGroup>
        </div>

    )

}

export default App;



const style = {
    margin: 'auto'

};

const sheep = {
    position: 'fixed',
    zIndex: -1,
    maxHeight: '90%',
    maxWidth:'100%',
    left:'0',
    right:'0',
    top:'0',
    bottom:'0',
    margin:'auto'
}
