import React, {useEffect, useRef, useState} from 'react';

import alienImg from '../../images/space/alien.png'
import moonImg from '../../images/space/moon.png'



import btn_alien from '../../images/btn_alien.png'
import btn_alien_active from '../../images/btn_alien_active.png'
import btn_moon from '../../images/btn_moon.png'
import btn_moon_active from '../../images/btn_moon_active.png'
import btn_nebel from '../../images/btn_nebel.png'
import btn_nebel_active from '../../images/btn_nebel_active.png'
import btn_sputnik from '../../images/btn_sputnik.png'
import btn_sputnik_active from '../../images/btn_sputnik_active.png'
import btn_sphere from '../../images/btn_sphere.png'
import btn_sphere_active from '../../images/btn_sphere_active.png'


import background from '../../images/space/background.png'

import back from '../../images/back.png'

import Tone from 'tone'



import Sputnik from './Sputnik'
import Sphere from './Sphere'
import Moon from './Moon'
import Stars from './Stars'
import Alien from './Alien'
import Nebel from './Nebel'
import RamenSpace from './RamenSpace'
import '../World.css'




function Space(props) {



    const [sputnik, setSputnik] = useState(false)
    const [sphere, setSphere] = useState(false)
    const [moon, setMoon] = useState(false)
    const [stars, setStars] = useState(false)
    const [alien, setAlien] = useState(false)
    const [nebel, setNebel] = useState(false)







    useEffect(() => {



        Tone.Transport.bpm.value = 120

        props.backgroundPlayer.mute = false
        props.moonPlayer.mute = true
        props.spherePlayer.mute = true
        props.sputnikPlayer.mute = true
        props.nebelPlayer.mute = true

        props.alienPlayer.mute = true
        props.alienPlayer.restart()

         props.backgroundPlayer.restart()
         props.moonPlayer.restart()
         props.spherePlayer.restart()
         props.sputnikPlayer.restart()
         props.nebelPlayer.restart()
        Tone.Transport.start()

        Tone.Transport.scheduleRepeat(()=>{
            //Tone.Transport.position = 0;
            Tone.Transport.start()
        }, '16m')

    },[])


    const handleSphere = () => {
        if (!sphere) {

            props.spherePlayer.mute = false
            setSphere(true)
        } else if (sphere) {

            props.spherePlayer.mute = true
            setSphere(false)
        }
    }

    const handleNebel = () => {
        if (!nebel) {
            props.nebelPlayer.mute = false
            setNebel(true)
        } else if (nebel) {
            props.nebelPlayer.mute=  true
            setNebel(false)
        }
    }

    const handleMoon = () => {
        if (!moon) {
            props.moonPlayer.mute = false
            setMoon(true)
        } else if (moon) {

            props.moonPlayer.mute = true
            setMoon(false)
        }
    }

    /*const handleStars = () => {
        if (!stars) {

            setStars(true)
        } else if (stars) {

            setStars(false)
        }
    }*/

    const handleAlien = () => {
        if (!alien) {

            props.alienPlayer.mute = false
            setAlien(true)
        } else if (alien) {

            props.alienPlayer.mute = true
            setAlien(false)
        }
    }

    const handleSputnik = () => {
        if (!sputnik) {

            props .sputnikPlayer.mute = false
            setSputnik(true)
        } else if (sputnik) {

            props.sputnikPlayer.mute = true
            setSputnik(false)
        }
    }



    const handleBack = () => {
        props.backgroundPlayer.mute = true
        props.alienPlayer.mute = true

        props.moonPlayer.mute = true
        props.spherePlayer.mute = true
        props.sputnikPlayer.mute = true
        props.nebelPlayer.mute = true
        //Tone.Transport.mute = true
        props.backgroundPlayer.stop()
        props.alienPlayer.stop()

        props.moonPlayer.stop()
        props.spherePlayer.stop()
        props.sputnikPlayer.stop()
        props.nebelPlayer.stop()
        Tone.Transport.stop();
        Tone.Transport.position = 0;
        Tone.Transport.cancel();
        //Tone.Transport.stop()
        props.choose(0)
    }





    return (

        <div>
            <img src={background} style={bgStyle}/>

            <Sphere show={sphere} sphere={props.sphereImg}/>

            <Nebel show={nebel}/>
            <Stars show={stars}/>
            <Moon show={moon}/>

            <Alien show={alien}/>
            <Sputnik show={sputnik}/>
            <RamenSpace/>
            <div style={buttons}>

                <img src={back}  style={backStyle} onClick = {handleBack} />


                <img src={sphere ? btn_sphere_active : btn_sphere}
                     onMouseOver={event => event.target.src = btn_sphere_active}
                     onMouseOut={event => (sphere ? event.target.src = btn_sphere_active : event.target.src = btn_sphere)}
                     style={crossStyle}
                     onClick = {handleSphere} />

                {/*<img src={stars ? btn_stars_active : btn_stars}
                     onMouseOver={event => event.target.src = btn_stars_active}
                     onMouseOut={event => (stars ? event.target.src = btn_stars_active : event.target.src = btn_stars)}
                     style={crossStyle}
                     onClick = {handleStars} />*/}

                <img src={nebel ? btn_nebel_active : btn_nebel}
                     onMouseOver={event => event.target.src = btn_nebel_active}
                     onMouseOut={event => (nebel ? event.target.src = btn_nebel_active : event.target.src = btn_nebel)}
                     style={crossStyle}
                     onClick = {handleNebel} />

                <img src={alien ? btn_alien_active : btn_alien}
                     onMouseOver={event => event.target.src = btn_alien_active}
                     onMouseOut={event => (alien ? event.target.src = btn_alien_active : event.target.src = btn_alien)}
                     style={crossStyle}
                     onClick = {handleAlien} />

                <img src={moon ? btn_moon_active : btn_moon}
                     onMouseOver={event => event.target.src = btn_moon_active}
                     onMouseOut={event => (moon ? event.target.src = btn_moon_active : event.target.src = btn_moon)}
                     style={crossStyle}
                     onClick = {handleMoon} />



                <img src={sputnik ? btn_sputnik_active : btn_sputnik}
                     onMouseOver={event => event.target.src = btn_sputnik_active}
                     onMouseOut={event => (sputnik ? event.target.src = btn_sputnik_active : event.target.src = btn_sputnik)}
                     style={crossStyle}
                     onClick = {handleSputnik} />






            </div>



        </div>


        /* <div style={style}>

         </div>*/
    )

}

export default Space;

const buttons = {
    position:'fixed',
    textAlign: 'center',
    width: '100vw',
    bottom:'0',
}

const backStyle = {
    position: 'fixed',
    left: '1vw',
    bottom: '1vh',
    maxWidth: '10vw',
    maxHeight: '10vh',
}

const bgStyle = {
    position: 'fixed',
    zIndex: -1,
    maxHeight: '90%',
    maxWidth:'100%',
    left:'0',
    right:'0',
    top:'0',
    bottom:'0',
    margin:'auto'
}


const crossStyle = {
    maxWidth: '10vw',
    maxHeight: '10vh',
    margin: '1%',
}


